import request from "@/router/axios";
/**
 * 用户名称登录功能
 * @returns
 */
export const loginByUsername = (tenantId, username, password) =>
  request({
    url: "/api/hzdstech-auth/oauth/token",
    method: "post",
    params: {
      tenantId,
      username,
      password,
      grant_type: "password",
      scope: "all",
    },
  });
