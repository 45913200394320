import {ElMessage} from "element-plus"
import axios from "axios";
import store  from "@/store";
import { Base64 } from "js-base64";
import website from "@/config/website.js";
// import store from "@/store/index.js"
import {getToken} from '@/util/auth';

//默认超时时间
axios.defaults.timeout = 10000;
//返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

//http request拦截
axios.interceptors.request.use((config) => {
  // const isToken = meta.isToken === false
  config.headers["Authorization"] = `Basic ${Base64.encode(
    `${website.clientId}:${website.clientSecret}`
  )}`;
  config.headers[website.tokenHeader] = "bearer "+getToken();
  return config;
},error =>{
  return Promise.reject(error)
});
// http response 拦截
axios.interceptors.response.use(res =>{
    //获取状态码
    const status = res.data.code || res.status;
    const statusWhiteList = website.statusWhiteList || [];
    const message = res.data.msg || res.data.error_description || '未知错误';
      //如果是401则跳转到登录页面
  if (status === 401) store.dispatch('FedLogOut').then(() => router.push({path: '/login'}));
    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
      ElMessage.error(message)
      return Promise.reject(new Error(message))
    }
    return res;
},error =>{
  return Promise.reject(new Error(error));
});
export default axios;
