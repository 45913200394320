<template>
  <router-view/>
</template>

<style>
/* 去除了下划线 */
.router-link-active {
  text-decoration: none;
}
/* 去除了下划线 */
a {
  text-decoration: none;
}
/* 去除了li 点 */
li {
  list-style: none;
}
/* 慢慢去除掉 用max_width */
.w {
  width: 1200px;
  margin: auto;
}
/* 最大宽度 */
.hz_max_width{
  width: 1200px;
  margin: auto;
}
</style>
