import { Message } from "element-plus";
import { loginByUsername } from "@/api/user-api";
import {setToken, setRefreshToken, removeToken, removeRefreshToken} from '@/util/auth'
import {setStore, getStore} from '@/util/store'
// initial state
const state = () => ({
  token: "",
  userInfo: {},
});

// getters
const getters = {
  getToken(state){
    return state.token
  }
};
// actions
const actions = {
  //根据用户名登录
  LoginByUsername({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginByUsername(userInfo.tenantId, userInfo.username, userInfo.password)
        .then((res) => {
          const data = res.data;
          if (data.error_description) {
            Message({
              message: data.error_description,
              type: "error",
            });
          } else {
            commit("SET_TOKEN", data.access_token);
            commit("SET_REFRESH_TOKEN", data.refresh_token);
            commit("SET_TENANT_ID", data.tenant_id);
            // commit("SET_USER_INFO", data);
            // commit("DEL_ALL_TAG");
            // commit("CLEAR_LOCK");
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  SET_TOKEN: (state, token) => {
    setToken(token);
    state.token = token;
    setStore({name: 'token', content: state.token})
  },
  SET_REFRESH_TOKEN: (state, refreshToken) => {
    setRefreshToken(refreshToken)
    state.refreshToken = refreshToken;
    setStore({name: 'refreshToken', content: state.refreshToken})
  },
  SET_TENANT_ID: (state, tenantId) => {
    state.tenantId = tenantId;
    setStore({name: 'tenantId', content: state.tenantId})
  },
  SET_USER_INFO: (state, userInfo) => {
    // if (validatenull(userInfo.avatar)) {
    //   userInfo.avatar = "/img/bg/img-logo.png";
    // }
    state.userInfo = userInfo;
    setStore({name: 'userInfo', content: state.userInfo})
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
